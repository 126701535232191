<template>
  <div class="container">
    <div class="navbar">
      <div class="navbar-right">
        <ul class="menus">
          <li
            :class="
              $route.name === item.name ? 'current-menu menu-item' : 'menu-item'
            "
            v-for="(item, index) in menuText"
            :key="index"
            @click="clickMenu(index)"
          >
            <a>{{ item.text }}</a>
          </li>
        </ul>
      </div>

      <div
        class="navbar-right-mobile"
        @click="showMobileMenu = !showMobileMenu"
      >
        <button type="button">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>

      <div class="navbar-left">
        <div class="search">
          <img class="search-icon"
            src="../../../assets/img/search.png"
            alt=""
            @click.stop="showSearch = !showSearch"
            v-show="!showSearch"
          />
          <div class="search-input" v-show="showSearch" v-clickoutside="handleClose" >
            <!-- style="touch-action: none;" -->
            <img
              src="../../../assets/img/search.png"
              alt=""
              @click.stop="showSearch = !showSearch"
            />
            <input
              v-model="searchVal"
              placeholder="搜索"
              class="focus-input"
              @keyup.enter="clickSearch"
            />
            <span
              :class="goIsMouse == true ? 'goMouseIn' : ''"
              @mouseenter="goIsMouse = true"
              @mouseleave="goIsMouse = false"
              @click="clickSearch"
              style="user-select: none;-webkit-user-select:none;z-index:9"
              >Go</span
            >
          </div>
        </div>
        <div class="change-lang">
          <div
            class="active-lang"
            @click.stop="showSelectLang = !showSelectLang"
          >
            <a href="javascript:;">{{ activeLangText }}</a>
            <img class="xl" src="../../../assets/img/xiala@2x.png" alt="" />
          </div>
          <div
            class="select-langs"
            v-show="showSelectLang"
            v-clickoutside="handleClose2"
          >
            <a href="javascript:;" @click="changeLang('cn')">简体中文</a>
            <a href="javascript:;" @click="changeLang('en')">ENGLISH</a>
            <a href="javascript:;" @click="changeLang('jp')">日本語</a>
          </div>
        </div>
      </div>

      <div
        class="navbar-collapse"
        v-show="showMobileMenu"
        @touchmove.prevent
        @mousewheel.prevent
      >
        <div
          class="navbar-right-mobile"
          @click="showMobileMenu = !showMobileMenu"
        >
          <button type="button">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>
        <ul class="mobile-menu">
          <li
            @click="
              $router.push({ path: '/home/lang' });
              showMobileMenu = false;
            "
          >
            <a href="javascript:;">{{ $t("navBar.title1") }}</a>
          </li>
          <li
            @click="
              $router.push({ path: `/c/lang/%20` });
              showMobileMenu = false;
            "
          >
            <a href="javascript:;">{{ $t("navBar.title2") }}</a>
          </li>
          <li
            @click="
              $router.push({ path: '/news/lang' });
              showMobileMenu = false;
            "
          >
            <a href="javascript:;">{{ $t("navBar.title6") }}</a>
          </li>
          <li
            @click="
              $router.push({ path: '/about/lang' });
              showMobileMenu = false;
            "
          >
            <a href="javascript:;">{{ $t("navBar.title4") }}</a>
          </li>
          <li
            @click="
              $router.push({ path: '/contact/lang' });
              showMobileMenu = false;
            "
          >
            <a href="javascript:;">{{ $t("navBar.title7") }}</a>
          </li>
          <div class="change-lang2">
            <!-- <div
              class="active-lang"
              @click.stop="showSelectLang = !showSelectLang"
            >
              <a href="javascript:;">{{ activeLangText }}</a>
              <img class="xl" src="../../../assets/img/xiala@2x.png" alt="" />
              v-show="showSelectLang"
              v-clickoutside="handleClose2"
            </div> -->
            <div class="select-langs">
              <a
                href="javascript:;"
                @click="
                  changeLang('cn');
                  showMobileMenu = false;
                "
                >简体中文</a
              >
              <a
                href="javascript:;"
                @click="
                  changeLang('en');
                  showMobileMenu = false;
                "
                >ENGLISH</a
              >
              <a
                href="javascript:;"
                @click="
                  changeLang('jp');
                  showMobileMenu = false;
                "
                >日本語</a
              >
            </div>
          </div>
        </ul>
      </div>
    </div>

    <div class="logo">
      <a href="javascript:;" @click="toHome"
        ><img src="../../../assets/img/logo-top.png" alt=""
      /></a>
    </div>
  </div>
</template>

<script>
const clickoutside = {
  // 初始化指令
  bind(el, binding, vnode) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.vueClickOutside = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  update() {},
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener("click", el.vueClickOutside);
    delete el.vueClickOutside;
  },
};
import { setup } from "../../../i18n/i18n";
export default {
  name: "NavBar",
  data() {
    return {
      goIsMouse: false,
      showMobileMenu: false,
      menu: "", //当前menu
      showSearch: false, //显示搜索框
      //activeInput: false,//控制搜索框样式
      searchVal: "",
      showSelectLang: false, //语言选择下拉
      // activeLangText:""
    };
  },
  directives: { clickoutside },
  mounted() {
    this.changeMenu();
  },
  methods: {
    toHome() {
      this.$router.push({ path: "/home/lang" }).catch((err) => {});
    },
    handleClose(e) {
      this.showSearch = false;
    },
    handleClose2(e) {
      this.showSelectLang = false;
    },
    //切换语言
    changeLang(lang) {
      console.log(lang);
      this.$i18n.locale = lang;
      setup(lang);
      window.location.reload();
    },
    changeMenu() {
      this.$nextTick(() => {
        this.menu = sessionStorage.getItem("currentMenu");
      });
    },
    //点击搜索
    clickSearch() {
      if (this.searchVal.trim() == "") return false;
      this.$router
        .replace({ path: "/c/lang/%20", query: { res: this.searchVal } })
        .catch((err) => {});
      if (this.$route.name == "Category") {
        window.location.reload()
      }
      // this.searchVal=""
    },
    //点击右边的menu
    clickMenu(index) {
      this.changeMenu();
      if (index === 0) {
        this.$router.push({ path: "/home/lang" }).catch((err) => {});
      } else if (index === 1) {
        this.$router.push({ path: `/c/lang/%20` }).catch((err) => {});
      } else if (index === 2) {
        this.$router.push({ path: `/news/lang` }).catch((err) => {});
      } else if (index === 3) {
        this.$router.push({ path: `/about/lang` }).catch((err) => {});
      } else if (index === 4) {
        this.$router.push({ path: `/contact/lang` }).catch((err) => {});
      }
    },
  },
  components: {},
  computed: {
    activeLang() {
      return localStorage.getItem("activeLang");
    },
    activeLangText() {
      let al = localStorage.getItem("activeLang");
      if (al == "cn") {
        return "简体中文";
      } else if (al == "en") {
        return "ENGLISH";
      } else if (al == "jp") {
        return "日本語";
      } else {
        return "简体中文";
      }
    },
    menuText() {
      return [
        { name: "Home", text: this.$t("navBar.title1") },
        { name: "Category", text: this.$t("navBar.title2") },
        { name: "News", text: this.$t("navBar.title6") },
        { name: "AboutUs", text: this.$t("navBar.title4") },
        { name: "ContactUs", text: this.$t("navBar.title7") },
      ];
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../assets/css/navBar.less";
</style>
