import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () => import('../views/home/Home.vue')
const Category = () => import('../views/category/Category.vue')
const ProductDetail = () => import('../views/category/childComps/ProductDetail.vue')
const Jobs = () => import('../views/jobs/Jobs.vue')
const AboutUs = () => import('../views/AboutUs/AboutUs.vue')
const ContactUs = () => import('../views/contactUs/ContactUs.vue')
const News = () => import('../views/news/News.vue')
const NewsDetail = () => import('../views/news/NewsDetail.vue')

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {name:'Home'}
  },
  {
    path: '/home/:lang',
    name: 'Home',
    component: Home,
    meta: {
      title_cn:"上海满帆精密机械科技有限公司",
      title_en:"Manfan-19 Years professional fashion bags,clothes,furniture manufacture!",
      title_jp:"上海満帆精密機械科技有限公司は",
      content_cn:{
        keywords:`箱包、时装包、手提包、单肩包、钱包、帆布包、化妆包、冰包、购物袋、皮包、背包、卫生用品、医用口罩、N95口罩、酒精湿巾、除菌湿巾、湿纸巾、抽纸、温度计、防护服、丁腈手套、TPE手套、PVC手袋、餐具、咖啡杯袋、保温杯、玻璃杯、啤酒杯、陶瓷杯、咖啡杯、小麦秸秆双重隔热水杯、健腹轮、普拉提圈、休闲椅子、折叠水桶、手持风扇、保冷垫、充气枕、蜡烛、花瓶、沥水篮、衣服、女装衣服、男装衣服、时尚服装、外套、裤子、T恤衫、毛衣、裙子、家具、家居用品、茶几、椅子、沙发、橱柜、衣橱、书架、电视柜、餐桌、书台、收纳、收纳盒、收纳筐、收纳柜、收纳架、厨房收纳、衣服收纳`,
        description:"上海满帆成立与2003年，总部坐落于上海陆家嘴，是一家集产品设计，研发，生产和销售为一体的进出口贸易生产型公司。出口的产品涵盖时装包、行李箱、服装、环保包装材料、家具、个人防护用品、机械设备以及其他生活用品。"
      },
      content_en:{
        keywords:"Bags/luggage case/Fashion bag/handbags/Shoulder bag/Wallet/Purse/Tote bag/Hobo bag/Cosmetic bag/Backpack/Shopping Bag/Messenger bag/Sling bag/Crossbody bag/Designer bags/Waist bag/Designer handbags/Handbag for women/Ladies bag/Canvas bag/leather bag/Coffee cup bag/Women bag/Mask/Wipes/TPE Gloves/Medical Surgical Mask/Nitrile Gloves/Alcohol Wipes/Medical infrared thermometer/Protective suit/Coffee cup/Glass cup/Ceramic cup/Thermos cup/Vacuum flask/GALSS VASE/Drain basket/Abdominal Wheel/Pilates circle/Yoga column/Leisure chair/Inflatable pillow/Cold Cushion/Wheat straw cutlery set/Handheld fan/Clothes/Jeans/Pants/T shirt/Coat/Skirt/Sweater/Furniture/Household /Coffee tables/ chairs/ Sofas/Cabinets/ Wardrobes/ Bookshelves/TV cabinets/ Dining tables/Bookshelves/Storage/ Storage box/Storage basket/Storage cabinet/Storage rack/Kitchen storage/ Clothes storage",
        description:"Shanghai M.F.CO.,LTD Established in 2002, we are one of the leading manufacturers of Sanitary Materials & Bags in Shanghai, China.We are major engaged in Mask & Fashion Bags series; including handbags, shopping bags, and mask.More than 500 skilled people, equipped latest international production line, strictly examination, ensure the best quality & safety products we produced."
      },
      content_jp:{
        keywords:"バッグ、ファッションバッグ、ショルダーバッグ、財布、ズックバッグ、化粧品バッグ、アイスバッグ、ショッピングバッグ、カバン、衛生用品、医療用マスク、N 95マスク、アルコールウェットティッシュ、除菌ウェットティッシュ、ウェットティッシュ、紙引き、温度計、防護服、ニトリル手袋、TPE手袋、PVC手袋、食器、コーヒーカップ、保温コップ、グラス、ビールカップ、陶磁器カップ、コーヒーカップ、麦わらの二重断熱コップ、健腹輪、プラティシュ、レジャー椅子、バケツを折り畳み、扇風機、保冷マット、空気枕、ろうそく、花瓶、水滴かご、服、レディース服、メンズ服、ファッション服、ジャケット、ズボン、Tシャツ、セーター、スカート、家具、家庭用品、コーヒーテーブル、椅子、ソファ、キャビネット、ワードローブ、本棚、テレビキャビネット、ダイニングテーブル、本棚、収納、収納ボックス、収納バスケット、収納キャビネット、収納ラック、キッチン収納、洋服収納",
        description:"2003年に設立され、本社は中国上海の陸家嘴に位置してます。製品の設計からはじめ、研究開発、製造、販売を統合する輸出貿易生産会社です。当社の輸出製品としては、ファッションバッグ、キャリアケース、地球にやさしい梱包材料、アパレル、家具、日用衛生用品、災害保護用品、機械設備、その他生活用品を取り扱っております。"
      },
    },
  },
  {
    path: '/c/:lang/:cid',
    name: 'Category',
    component: Category,
    meta: {
    }
  },
  {
    path: '/p/:lang/:id',
    name: "prod",
    component: ProductDetail,
    meta: {
    }
  },
  {
    path: '/jobs/:lang',
    name: "Jobs",
    component: Jobs,
    meta: {
    }
  },
  {
    path: '/about/:lang',
    name: "AboutUs",
    component: AboutUs,
    meta: {

    }
  },
  {
    path: '/contact/:lang',
    name: "ContactUs",
    component: ContactUs,
    meta: {

    }
  },
  {
    path: '/news/:lang',
    name: "News",
    component: News,
    meta: {

    }
  },
  {
    path: '/:title/:lang/:nid',
    name: "NewsDetail",
    component: NewsDetail,
    meta: {

    }
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes,
  // scrollBehavior (to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // }
})


///router为路由对象，添加beforeEach导航守卫
router.beforeEach((to, from, next) => {
  let lang = localStorage.getItem('Request-Language');

  sessionStorage.setItem('currentMenu', to.name);
  sessionStorage.setItem('cateload', 0);
  window.scrollTo(0, 0);

  if (to.meta.content_cn || to.meta.content_en || to.meta.content_jp) {
		let head = document.getElementsByTagName('head');
		let meta = document.createElement('meta');
    if(lang=='cn'){
      document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content_cn.keywords)
      document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content_cn.description)
    }else if(lang=='en'){
      document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content_en.keywords)
      document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content_en.description)
    }else if(lang=='jp'){
      document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content_jp.keywords)
      document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content_jp.description)
    }

		meta.content = to.meta.content;
		head[0].appendChild(meta)
	}

	if (to.meta.title_cn || to.meta.title_en || to.meta.title_jp) {
    if(lang=='cn'){
      document.title = to.meta.title_cn;
    }else if(lang=='en'){
      document.title = to.meta.title_en;
    }else if(lang=='jp'){
      document.title = to.meta.title_jp;
    }
	}
  
  if ((to.params.lang !== lang)) {
    if (from.params.lang === 'cn' || from.params.lang === 'en' || from.params.lang === 'jp') {
      to.params.lang = from.params.lang
    }
    else {
      to.params.lang = lang
    }
    next({
      path: to.path,
      name: to.name,
      query: to.query,
      params: to.params,
    })
  } else {
    next()
  }


})

export default router
