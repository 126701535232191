<template>
  <div id="app">
    <nav-bar></nav-bar>
    <!-- <keep-alive include="News,Category"> -->
      <router-view/>
      <!-- </keep-alive> -->
    <footers></footers>
  </div>
</template>
<script>
import NavBar from "./components/content/navbar/NavBar";
import Footers from "./components/content/footer/Footer";
export default {
  name: "app",
  data() {
    return {
    };
  },
  methods: {
  },
  components: {
    NavBar,
    Footers
  },
};
</script>

<style scoped lang="less">
</style>

