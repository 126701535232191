import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element'
import './assets/css/global.css'
import 'element-ui/lib/theme-chalk/index.css';
import FastClick from 'fastclick'
import VueLazyload from "vue-lazyload";
import BaiduMap from 'vue-baidu-map'
import VueQr from 'vue-qr'
import './assets/iconfonts/iconfont.css'

import AOS from 'aos';
import 'aos/dist/aos.css';

import i18n from './i18n/i18n'


//图片懒加载
Vue.use(VueLazyload, {
  loading: require('./assets/img/s1.png')
});


//解决移动端300ms延迟
// FastClick.attach(document.body);


AOS.init({
  startEvent: 'load',
  duration: 800,
})

//二维码
Vue.use(VueQr)

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'bHE3AkWkfxES4QioLDLlPALnTodFaGBS'
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
