import {request} from './request'

export function getJobs(){
    return request({
        url:'/api/Jobs/GetJobs'
    })
}

export function getBottomJobs(){
    return request({
        url:'/api/Jobs/GetBottomJobs?top=0'
    })
}