import Vue from 'vue';
import {
    Row,
    Col,
    Message,
    Form,
    FormItem,
    Input,
    Button,
} from 'element-ui'

Vue.use(Row)
Vue.use(Col)
Vue.use(FormItem)
Vue.use(Form)
Vue.use(Input)
Vue.use(Button)
Vue.prototype.$message = Message //消息提示