<template>
  <div class="footer">
    <el-row>
      <el-col :xs="24" :sm="24">
        <div class="footer-logo" data-aos="fade-up">
          <a href="javascript:;" @click="$router.push({path:'/home/lang'})"><img  src="../../../assets/img/logo.png" alt="" /></a>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="10" type="flex" justify="space-between" style="flex-wrap:wrap">
      <!-- 联系我们 -->
      <el-col :xs="24" :sm="12" :md="8">
        <!-- 中 -->
        <div class="footer-item" data-aos="fade-up" v-if="lang=='cn'">
          <div class="footer-title">
            <h5>{{$t('footer_title.title1')}}</h5>
          </div>
          <div class="footer-text">
            <p>{{$t('footer_title.companyName')}}</p>
          </div>
          <div class="footer-text">
            <p>{{$t('footer_item1.email')}}：<a href="mailto:info@mffind.com">info@mffind.com</a></p>
          </div>
          <div class="footer-text">
            <p>{{$t('footer_item1.title4')}}：+86 21-5877-5223</p>
          </div>
          <div class="footer-text">
            <p>QQ：3568244521</p>
          </div>
          <div class="footer-text">
            <p>{{$t('footer_item1.title2')}}：{{$t('footer_title.address')}}</p>
          </div>
        </div>
        <!-- 英 -->
        <div class="footer-item" data-aos="fade-up" v-else-if="lang=='en'">
          <div class="footer-title">
            <h5>{{$t('footer_title.title1')}}</h5>
          </div>
          <div class="footer-text">
            <p>{{$t('footer_title.companyName')}}</p>
          </div>
          <div class="footer-text">
            <p>{{$t('footer_item1.email')}}：<a href="mailto:info@mffind.com">info@mffind.com</a></p>
          </div>
          <div class="footer-text">
            <p>{{$t('footer_item1.title4')}}：+86 21-5877-5223</p>
          </div>
          <div class="footer-text">
            <p>{{$t('footer_item1.title5')}}：+86 21-5877-5990</p>
          </div>
          <div class="footer-text">
            <p>Skype：<a class="email" href="mailto:mffind@outlook.com">mffind@outlook.com</a></p>
          </div>
          <div class="footer-text">
            <p>{{$t('footer_item1.title8')}}：+8619916746923</p>
          </div>
        </div>
        <!-- 日 -->
        <div class="footer-item" data-aos="fade-up" v-else>
          <div class="footer-title">
            <h5>{{$t('footer_title.title1')}}</h5>
          </div>
          <div class="footer-text">
            <p>{{$t('footer_item1.title4')}}：+86 133 2181 9736</p>
          </div>
          <div class="footer-text">
            <p>{{$t('footer_item1.email')}}：<a href="mailto:info@mffind.com">info@mffind.com</a></p>
          </div>
          <div class="footer-text">
            <p>{{$t('footer_item1.title8')}}：+86 21-5877-5223</p>
          </div>
          <div class="footer-text">
            <p>{{$t('footer_item1.title5')}}：+86 21-5877-5990</p>
          </div>
          <div class="footer-text">
            <p>Skype：<a class="email" href="mailto:mffind@outlook.com">mffind@outlook.com</a></p>
          </div>
          <div class="footer-text">
            <p>QQ：3568244521</p>
          </div>
          <div class="footer-text">
            <p>{{$t('footer_item1.title2')}}：{{$t('footer_title.address')}}</p>
          </div>
        </div>
      </el-col>
      <!-- 招聘 -->
      <el-col :xs="24" :sm="12" :md="7">
        <div class="footer-item"  data-aos="fade-up">
          <div class="footer-title">
            <h5>{{$t('footer_title.title2')}}</h5>
          </div>
          <div class="footer-text" v-for="(item,index) in jobs" :key="index">
            <router-link :to="{path:`/jobs/lang`}">{{item}}</router-link>
          </div>
        </div>
      </el-col>
      <!-- 关注我们 -->
      <el-col :xs="24" :sm="12" :md="5">
        <div class="footer-item"  data-aos="fade-up">
          <div class="footer-title">
            <h5>{{$t('footer_title.title3')}}</h5>
          </div>
          <div class="footer-map footer-text">
            <a href="https://www.facebook.com/ManfanBags">Facebook</a>
          </div>
          <div class="footer-map footer-text">
            <a href="https://www.linkedin.com/company/shanghai-m-f-co-ltd/mycompany/">Linkedin</a>
          </div>
          <div class="footer-map footer-text">
            <a href="https://www.youtube.com/channel/UC1L4xw4CFq6a27Tjk4VpH2Q">Youtube</a>
          </div>
          <div class="footer-map footer-text">
            <a href="https://twitter.com/manfanbags">Twitter</a>
          </div>
        </div>
      </el-col>
      <!-- 扫码 -->
      <el-col :xs="24" :sm="12" :md="4">
        <div class="footer-item"  data-aos="fade-up">
          <div class="footer-title">
            <h5>{{$t('footer_title.title4')}}</h5>
          </div>
          <div class="footer-qrcode">
            <!-- <img src="../../../assets/img/qrcode.png" alt="" /> -->
            <vue-qr :text="downloadData.url" :dotScale="0.5"  :size="160" :margin="5"></vue-qr>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="24" :sm="24">
        <div class="copy">
          Copyright © {{thisYear}} Manfan. All rights reserved.
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {getBottomJobs} from '../../../network/jobs'
export default {
  name: "",
  data() {
    return {
      jobs:[],
      downloadData: {
          url: window.location.href,
      }
    };
  },
  created(){
    this.getJobs()
  },
  mounted(){
  },
  methods: {
    getJobs(){
      getBottomJobs().then(res=>{
        // console.log(res.data);
        this.jobs=res.data
      })
    }
  },
  components: {},
  computed:{
    thisYear(){
      let date=new Date()
      return date.getFullYear()
    },
    lang(){
      return window.localStorage.getItem('Request-Language')
    }
  }
};
</script>

<style scoped lang="less">
@import "../../../assets/css/footer.less";
</style>
