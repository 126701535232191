import enLocale from 'element-ui/lib/locale/lang/en'
const en = {
    navBar: {
        title1: 'HOME',
        title2: 'OUR PRODUCTS',
        title3: 'Job Opportunity',
        title4: 'ABOUT US',
        title5: 'Category',
        title6: 'NEWS',
        title7: "CONTACT US",
    },
    footer_title :{
        title1: 'Contact us',
        title2: 'Job offers',
        title3: 'Follow us',
        title4: 'Scan code to browse on mobile phone',
        companyName:'Shanghai ManFan Finery Machine Technology CO., LTD',
        address:'Room 20b, Lekai building, 660 ShangCheng Road, Pudong New Area, Shanghai 200120',
        person:'Wang Mei'
    },
    footer_item1:{
        title1:"The Company",
        title2:'Address',
        title3:'Working Day Contact',
        title4:"Tel",
        title5:"Fax",
        title6:"Hour Contact",
        title7:"Monday to Friday",
        title8:"Whatsapp",
        title9:"Contact Person",
        email:"Email",
    },
    jobs:{
        title1:"Career Opportunities",
        title2:"Job Requirements",
        title3:"Description Of Job",
        title4:"Apply For a Position"
    },
    news:{
        title1:"News",
        title2:'NewsDetail',
        tip:"Failed to get news category!",
        tip2:"Failed to get news!",
        tip3:"Get more news fail!",
        tip4:"Failed to get news details!",
        date:"Date",
        source:"Source"
    },
    about:{
        title1:"About us",
        title2:"Contact Details",
        intro:"Shanghai Manfan was founded in 2003. We are an import and export company. Our head office is based in Shanghai, with sales and client relationship presence in Tokyo, New York and Frankfurt. We have our own manufacture factories in Shanghai and Zhejiang, China. We also have more 300 supply partners in east coast provinces in China. Our exports products include hand bags, luggage, clothes, packing materials, recycling materials, furniture, PPEs, machinery and other groceries. Our clientele includes top retail brands in Japan, USA and Germany. Quality product, competitive price, timely delivery, effective process management and full range service are the five key elements we keep a long and win-win relationship with our clients. We are innovative and proactive. We not only follow clients’ orders but we continue to provide clients with new product ideas and business opportunities. We expand our product offerings every year. Client interest goes first is deeply imprinted in our minds. Nothing is impossible in Manfan. We know China, we know China’s manufacture and we know our client. Find us, contact us, and we are the one you are looking for.",
        title3:"18 years history",
        h_time1:"In 2003",
        h_time2:"In 2005",
        h_time3:"In 2007",
        h_time4:"In 2012",
        h_time5:"In 2014",
        h_time6:"In 2016",
        h_time7:"In 2017",
        h_time8:"In 2018",
        h_time9:"In 2019",
        h_time10:"In 2020",
        h_time11:"In 2021",
        ht1:"Manfan started the export business of light industrial products, clothing, furniture, groceries, protective products, etc. to overseas markets mainly in Japan, Europe and the United States.",
        ht2:" The quality inspection center and protective equipment factory were established.",
        ht3:"A luggage factory was established to produce all kinds of bags, apparel and other products.",
        ht4:"The sewing factories were established in Shanghai, Zhangjiagang, Anhui, Zhejiang and other places in China.",
        ht5:"A luxury packaging production line was established with our strategic partners in Shanghai, China. The products are cost-effective and can be independently developed and innovated.",
        ht6:"A metal appliance production base was established in cooperation in Yongkang, Zhejiang, China.",
        ht7:"The cooperation relationship was built with the furniture and home furnishing research and development center in Kunshan.",
        ht8:"A subsidiary was established in Tokyo.",
        ht9:"The New York sales representative office was established.",
        ht10:"Own Brand was created, start to design and manufacture.",
        ht11:"Develop e-commerce platform sales business.",
        title4:"Our Location",
        title5:"Inquiry",
        i1:"Name",
        i2:"Phone",
        i3:"Email",
        i4:"Message",
        i5:"Country",
        send:"Submit",
        send_fail:"Message delivery failed!",
        send_success:"Message sent successfully!",
        tip1:"Length from 2 to 24 characters",
        tip2:"Please enter your email address",
        tip3:"Please enter the correct email address",
        tip4:"Please enter your message",
    },
    contact:{
        t1:"Contact Us",
        title1:"Phone",
        title2:"Email",
        title3:"Whatsapp",
        title4:"Address",
        title1_t1:"+86 133 2181 9736",
        title1_t2:"7*24 hours available",
        title2_t1:"info@mffind.com",
        title2_t2:"Dear friends, You will be replied within 12 hours.",
        title3_t1:"+86 199 1674 6923",
        title3_t2:"7*24 hours waiting for you",
        title4_t1:"Room 20b, Lekai building, 660 ShangCheng Road, Pudong New Area, Shanghai 200120",
        tip:"Please contact us for more information about products, Sample Support, FOB Pricing etc. You will be replied within 12 hours."
    },
    prodDetail:{
        title1:'Single product details',
        title2:'Name',
        title3:'Numbering',
        title4:'Description',
        title5:'Contact a trade representative',
        title6:'Contact us',
        title7:'Other Instructions'
    },
    cate:{
        title:"All"
    },
    ...enLocale
}

export default en