import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import Vue from 'vue'
import en from './langs/en'
import cn from './langs/cn'
import jp from './langs/jp'

Vue.use(VueI18n)

let localLang="";
let navLang = navigator.language.substring(0, 2); //浏览器语言
if(navLang=='zh'){
    localLang='cn'
}else if(navLang=='en'){
    localLang='en'
}else if(navLang=='ja'){
    localLang='jp'
}

let DEFAULT_LANG = '';
if(window.location.href.indexOf('/cn')!==-1){
    DEFAULT_LANG = 'cn' 
}else if(window.location.href.indexOf('/en')!==-1){
    DEFAULT_LANG = 'en' 
}else if(window.location.href.indexOf('/jp')!==-1){
    DEFAULT_LANG = 'jp' 
}else{
    DEFAULT_LANG = localLang // 默认语言为系统语言
}

const SESSION_KEY = 'Request-Language' // localStorage来存放的key，名字随便定，接下来会用到。

const locales = { // 引入zh.json以及en.json
    cn,
    en,
    jp
}

const i18n = new VueI18n({
    silentFallbackWarn: true,  // 设置为true后，在组件内使用时在浏览器不会报警告
    locale: DEFAULT_LANG,    // 语言标识
    messages :locales
})
locale.i18n((key, value) => i18n.t(key, value)) //重点：为了实现element插件的多语言切换


export const setup = lang => { //切换语言的函数，lang为语言标识，cn/en/jp
    // 在此判断lang的值，如果未定义，则让lang默认为DEFAULT_LANG，目的是为了让用户在未选择语言的时候默认为中文。
    if (lang == undefined) {
        lang = window.localStorage.getItem(SESSION_KEY)
        if (locales[lang] == undefined) {
            lang = DEFAULT_LANG
        }
    }
    // 若lang有值，那么存入localStorage中，key为SESSION_KEY,value为lang。
    window.localStorage.setItem(SESSION_KEY, lang)
    Object.keys(locales).forEach(item => {
        document.body.classList.remove(`lang-${item}`)
    })
    document.body.classList.add(`lang-${lang}`)
    document.body.setAttribute('lang', lang)

    Vue.config.lang = lang
    i18n.locale = lang
    window.localStorage.setItem('activeLang', lang) || 'cn'
}

setup()
export default i18n