import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const cn = {
    navBar: {
        title1: '首页',
        title2: '我们经营的品类',
        title3: '工作机会',
        title4: '关于我们',
        title5: '品类',
        title6: "新闻",
        title7: "联系我们",
    },
    footer_title :{
        title1: '联系我们',
        title2: '工作机会',
        title3: '关注我们',
        title4: '扫码在手机上浏览',
        companyName:'上海满帆精密机械科技有限公司',
        address:'上海市浦东新区商城路660号乐凯大厦20楼20B',
        person:'王梅'
    },
    footer_item1:{
        title1:"公司",
        title2:'地址',
        title3:'工作日联系方式',
        title4:"电话",
        title5:"传真",
        title6:"小时联系方式",
        title7:"周一到周五",
        title8:"手机",
        title9:"联系人",
        email:"邮箱",
    },
    jobs:{
        title1:"工作机会",
        title2:"任职要求",
        title3:"职位描述",
        title4:"申请职位"
    },
    news:{
        title1:"新闻",
        title2:'新闻详情',
        tip:"获取新闻类别失败！",
        tip2:"获取新闻失败！",
        tip3:"获取更多新闻失败！",
        tip4:"获取新闻详情失败！",
        date:"日期",
        source:"来源"
    },
    about:{
        title1:"关于我们",
        title2:"联系方式",
        intro:"上海满帆创建于2003年。我们是一家进出口贸易公司。公司总部设立在上海，在日本东京，美国纽约和德国法兰克福都有销售和客户服务团队。我们在中国上海和浙江都有全资下属的工厂，同时在全国范围有300多家关系紧密的供应商。我们出口的产品涵盖时装包、行李箱、服装、环保包装材料、家具、个人防护用品、机械设备以及其他生活用品。我们的客户包含多家日本、美国和德国的顶级零售品牌商。高质量的产品、有竞争力的价格、及时的交付、高效的过程管理以及全程全方位的服务是我们能供赢取和客户保持长期及双赢关系的五个成功因素。我们持续保持创新并积极与客户沟通。我们及时完成客户的订单的同时持续给客户提供创新的产品创意和赚钱商机。我们每年都持续扩充公司的产品线。客户利益至上深植于我们的内心。在上海满帆没有不可能完成的客户订单。我们了解中国，了解中国的制造业，了解我们的客户。找到我们，联系我们，我们是客户的最佳之选。",
        title3:"18年历史",
        h_time1:"2003年",
        h_time2:"2005年",
        h_time3:"2007年",
        h_time4:"2012年",
        h_time5:"2014年",
        h_time6:"2016年",
        h_time7:"2017年",
        h_time8:"2018年",
        h_time9:"2019年",
        h_time10:"2020年",
        h_time11:"2021年",
        ht1:"开启了出口贸易业务经营的轻工产品，服装，家具，杂货，防护产品等出口到以日本、欧美为主的海外市场。",
        ht2:"品检中心和防护用品厂的成立。",
        ht3:"成立了箱包工厂，生产包，服饰等产品。",
        ht4:"我们在中国上海、张家港、安徽、浙江等多个地方合作建立了缝纫加工厂。",
        ht5:"与战略合作伙伴共同建立了位于中国上海的奢侈品包装生产线，产品性价比高，并可自主研发，创新。",
        ht6:"在中国浙江的永康地区合作建立了金属用具的生产基地。",
        ht7:"在昆山合作家具家居研发中心。",
        ht8:"在东京成立子公司。",
        ht9:"成立纽约销售代表处。",
        ht10:"开始设计制造自有品牌。",
        ht11:"发展电商平台销售业务。",
        title4:"我们的位置",
        title5:"询价",
        i1:"姓名",
        i2:"电话",
        i3:"邮件",
        i4:"留言",
        i5:"公司名称",
        send:"提交",
        send_fail:"留言发送失败!",
        send_success:"留言发送成功!",
        tip1:"长度在 2 到 24 个字符",
        tip2:"请输入邮箱地址",
        tip3:"请输入正确的邮箱地址",
        tip4:"请输入您的留言",
    },
    contact:{
        t1:"联系我们",
        title1:"电话",
        title2:"邮箱",
        title3:"QQ",
        title4:"地址",
        title1_t1:"+86 133 2181 9736",
        title1_t2:"期待与您的合作",
        title2_t1:"info@mffind.com",
        title2_t2:"欢迎咨询！",
        title3_t1:"3568244521",
        title3_t2:"在线咨询！",
        title4_t1:"上海市浦东新区商城路660号乐凯大厦20B,20F 200120",
        tip:"产品和价格咨询，请联系我们，我们会在12小时以内回复"
    },
    prodDetail:{
        title1:'单品详情',
        title2:'名称',
        title3:'编号',
        title4:'描述',
        title5:'联系贸易代表',
        title6:'联系我们',
        title7:'其他说明'
    },
    cate:{
        title:"全部"
    },
    ...zhLocale
}

export default cn